import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Row } from 'react-bootstrap'
import { colors } from '../../../constants/colors'

export const Container = styled(`div`)(({ theme }) => ({
    padding: "20px 250px",

    [theme.breakpoints.down('xl')]: {
        padding: "10px 100px",
    },
    [theme.breakpoints.down('lg')]: {
        padding: "10px 50px",
    },
    [theme.breakpoints.down('md')]: {
        padding: "10px 8px",
    }
}))

export const MarginRow = styled(Row)(() => ({
    marginTop: 8,

}))

export const CenterContainer = styled(Row)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const TypeBox = styled(Box)(() => ({
    padding: "5px",
    margin: "10px 10px",
    textAlign: "center",
    border: `1px solid ${colors.gray}`,
    borderRadius: 10,
    position: "relative",
    width: 300
}));