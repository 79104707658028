import { REGISTER_TYPE } from '../../utils/enum'
import BaseAPI from '../api'

const path = 'app/installment'

interface calculate {
    type: REGISTER_TYPE
    product: {
        model: string
        storage: string
        color: string
    }
    period: number,
    combo: any
    productCode: string
    condition?: string
}

export default class InstallMentApi extends BaseAPI {

    static calculate(payload: calculate): Promise<any> {
        return this.api.post(`${path}/calculate`, payload).then((res) => res)
    }
}
