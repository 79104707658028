export enum CAREER {
    GOVERMENT = "พนักงานราชการ",
    PERMANENT = "พนักงานเงินเดือน",
    TEMPORARY = "พนักงานชั่วคราว/พนักงานอิสระ",
    OWNER = "เจ้าของกิจการ"
}

export enum REGISTER_TYPE {
    INSTALLMENT = "INSTALLMENT", // ผ่อน
    TURN1 = "TURN1", // เทิร์น 1 เทิร์นเครื่องเก่ารับเครื่องเดิม
    TURN2 = "TURN2", // เทิร์น 2 เทิร์นเครื่องเก่ารับเครื่องใหม่
    SECONDHAND = "SECONDHAND", // ขายขาด
}

export enum REGISTER_OPTIONS_TYPE {
    INSTALLMENT = "INSTALLMENT",
    TURN1 = "TURN1",
    SECONDHAND = "SECONDHAND"
}

export enum REGISTER_TYPE_TH {
    INSTALLMENT= "ผ่อนสินค้าราคาถูก",
    TURN1= "เทิร์นสินค้าราคาดี",
    SECONDHAND= "ขายสินค้าให้ราคาสูง"
};

export enum REGISTER_BRANCH_TYPE {
    INSTALLMENT = "INSTALLMENT",
    SECOND = "SECOND"
}

export const careerVal = (val: string) => {
    switch (val) {
        case 'พนักงานราชการ':
            return 'สังกัดหน่วยงาน'
        case 'พนักงานเงินเดือน':
            return 'ลักษณะงานประจำ'
        case 'พนักงานชั่วคราว/พนักงานอิสระ':
            return 'ลักษณะงานที่ทำ'
        case 'เจ้าของกิจการ':
            return 'ลักษณะธุรกิจ'
        default:
            break;
    }
}

export const careerOcc = (val: string) => {
    switch (val) {
        case 'พนักงานราชการ':
            return 'ชื่อหน่วยงาน'
        case 'พนักงานเงินเดือน':
            return 'ชื่อบริษัท'
        case 'พนักงานชั่วคราว/พนักงานอิสระ':
            return 'ชื่อร้านที่ทำงาน/บริษัทที่จ้าง'
        case 'เจ้าของกิจการ':
            return 'ชื่อกิจการ/ร้านค้า'
        default:
            break;
    }
}

export const careerLocation = (val: string) => {
    switch (val) {
        case 'พนักงานราชการ':
            return 'ที่อยู่หน่วยงาน'
        case 'พนักงานเงินเดือน':
            return 'ที่อยู่ที่ทำงาน'
        case 'พนักงานชั่วคราว/พนักงานอิสระ':
            return 'ที่อยู่ร้านที่ทำงาน/บริษัทที่จ้าง'
        case 'เจ้าของกิจการ':
            return 'ที่อยู่กิจการ/ร้านค้า'
        default:
            break;
    }
}