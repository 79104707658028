import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InstallMentApi from "../../../api/installment/installment.api";
import OptionsApi from "../../../api/option/option.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import PreviewImage from "../../../components/image/previewImage";
import HelperText from "../../../components/input/helperText";
import InputTextField from "../../../components/input/inputTextField";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { colors } from "../../../constants/colors";
import { numberFormat } from "../../../utils/common";
import { FromRegister, Product } from "../type-register";
import * as Styles from "./layout-product.style";
import { amplitudeTrack } from "../../../utils/amplitude/amplitude";
import useWindowSize from "../../../utils/useWindowSize";
import { useLocation } from "react-router-dom";
import { REGISTER_TYPE, REGISTER_TYPE_TH } from "../../../utils/enum";

const initStateErrorMessage = {
  PRODUCT_HAND: ``,
  PRODUCT_GROUP: ``,
  PRODUCT_MODEL: ``,
  PRODUCT_STORAGE: ``,
  PRODUCT_COLOR: ``,
  PRODUCT_PERIOD: ``,
  PRODUCT_COMBO: ``,
  PRODUCT_BRANCH: ``,
};

const { INSTALLMENT } = REGISTER_TYPE;

const ININT_PROD = { code: "", text: "" };

interface props {
  data?: any;
  checkedItems?: any;
  onClickNext: () => void;
  onChange?: (value: any) => void;
  checkedItemsVal?: (value: any) => void;
  optionsPeriodChoice: any[];
  setOptionsPeriodChoice: (value: any[]) => void;
}
export default function LayoutProduct(props: props) {
  const { optionsPeriodChoice, setOptionsPeriodChoice } = props;
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [data, setData] = useState<FromRegister>(props.data);
  const [width, height] = useWindowSize();
  const [type, setType] = useState<string>(props.data?.type || "INSTALLMENT" || "-");
  const [productHand, setProductHand] = useState<string>(props.data?.productHand || "NEW");
  const [productGroup, setProuctGroup] = useState<string>(props.data?.productGroup);
  const [productModel, setProductModel] = useState<any[]>([]);
  const [product, setProduct] = useState<Product>(props.data?.product?.model || { ...ININT_PROD });
  const [storageModel, setStorageModel] = useState<any[]>([]);
  const [storage, setStorage] = useState<Product>(props.data?.product?.storage || { ...ININT_PROD });
  const [branchData, setBranchData] = useState<any[]>([]);
  const [branch, setBranch] = useState<string>(props.data?.interestedBranch);
  const [colorModel, setColorModel] = useState<any[]>([]);
  const [color, setColor] = useState<Product>(props.data?.product?.color || { ...ININT_PROD });
  const [friendAdvisorCode, setFriendAdvisorCode] = useState<string>(props.data?.friend_advisor_code);
  const [installments, setInstallMents] = useState<number>(props.data?.installments || 0);
  const [period, setPreiod] = useState<number>(props.data?.period || 0);
  const [downPayment, setDownPayment] = useState<string>(props.data?.downPayment || "0");
  // const [downPaymentAss, setDownPaymentAss] = useState<number>(0)
  const [productComboData, setProductComboData] = useState<any[]>([]);
  const [productCombo, setProductCombo] = useState<any[]>(props.data?.combo || []);
  const [checkedItems, setCheckedItems] = useState<any[]>(props.checkedItems || []);
  const [productCombCheckboxData, setProductComboCheckboxData] = useState<any[]>([]);
  const [productCode, setProductCode] = useState<string>(props.data?.product?.code || "");
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const PARAMS_TYPE = params.get("type");
  const PARAMS_CATEGORY = params.get("category");
  const PARAMS_MODEL = params.get("model");
  const PARAMS_STORAGE = params.get("storage");
  const PARAMS_COLOR = params.get("color");

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data);
    }
    if (props.checkedItemsVal) {
      props.checkedItemsVal(checkedItems);
    }
  }, [data]);

  useEffect(() => {
    let group;
    switch (PARAMS_CATEGORY) {
      case "Smartphone":
        group = "iPhone";
        break;
      case "Tablet":
        group = "iPad";
        break;
      default:
        break;
    }
    if (PARAMS_MODEL && !props.data?.product?.model && !_.isEmpty(productModel)) {
      const product =_.find(productModel, (val: any) => val.label === PARAMS_MODEL) || {...ININT_PROD};
      setProduct(product);
    }
    if (PARAMS_STORAGE && !props.data?.product?.storage && !_.isEmpty(storageModel)) {
      const storage =_.find(storageModel, (val: any) => val.label === PARAMS_STORAGE) || {...ININT_PROD};
      setStorage(storage);
    }
    if (PARAMS_COLOR && !props.data?.product?.color && !_.isEmpty(colorModel)) {
      const color =_.find(colorModel, (val: any) => val.label === PARAMS_COLOR) || {...ININT_PROD};
      setColor(color);
    }
    if (PARAMS_CATEGORY && !props.data?.product?.productGroup) {
      setProuctGroup(group || "iPhone");
    }
  }, [PARAMS_CATEGORY, PARAMS_MODEL, PARAMS_STORAGE, PARAMS_COLOR]);

  const loadProduct = useCallback(() => {
    if (type && productGroup && productHand) {
      let condition: any = {};
      if (type) condition = { ...condition, type: type };
      if (productGroup) condition = { ...condition, category: productGroup === "iPhone" ? "Smartphone" : "Tablet" };
      if (productHand) condition = { ...condition, productHand: productHand || "NEW" };
      OptionsApi.productModel(condition).then((res) => {
        if (res.status === 200) {
          setProductModel(res.data.model);
        }
      });
    }
  }, [type, productGroup, productHand]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const loadBranch = useCallback(() => {
    let condition: any = {};
    if (type) {
      condition = { ...condition, type };
    }
    OptionsApi.registerBranch(condition).then((res) => {
      if (res.status === 200) {
        setBranchData(res.data.result);
      }
    });
  }, [type, productHand]);

  useEffect(() => {
    loadBranch();
  }, [loadBranch]);

  useEffect(() => {
    if (product.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: type };
      if (product.text) condition = { ...condition, model: product.text };
      if (productHand) condition = { ...condition, productHand: productHand };
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageModel(res.data.storage);
        }
      });
    }
  }, [product.text]);

  useEffect(() => {
    if (type && product.text && storage.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: type };
      if (product.text) condition = { ...condition, model: product.text };
      if (storage.text) condition = { ...condition, storage: storage.text };
      if (productHand) condition = { ...condition, productHand: productHand };
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorModel(res.data.color);
        }
      });
    }
  }, [storage.text]);

  useEffect(() => {
    if (product.text && storage.text && productGroup === "iPad") {
      let condition: any = {};
      if (product.text) condition = { ...condition, name: product.text };
      if (storage.text) condition = { ...condition, storage: storage.text };
      OptionsApi.productCombo(condition).then((res) => {
        if (res.status === 200) {
          setProductComboData(res.data.result);
          setProductComboCheckboxData(res.data.result.checkbox);
        }
      });
    }
  }, [productModel, storage.text]);

  useEffect(() => {
    if (type && period && product.text && storage.text && color.text) {
      calculate();
    }
  }, [period, productCombo]);

  useEffect(() => {
    if (product.text && storage.text && color.text && productHand) {
      const condition: any = {
        type,
        model: product.text,
        storage: storage.text,
        color: color.text,
        productHand,
      };
      OptionsApi.getProductCode(condition)
        .then((res) => {
          if (res.status === 200) {
            setProductCode(res?.data?.product_code);
            setOptionsPeriodChoice(res?.data?.period || []);
          }
        })
        .catch(() => {
          setProductCode("");
          setOptionsPeriodChoice([]);
        });
    }
  }, [color.text]);

  const calculate = async () => {
    const payload = {
      type: INSTALLMENT,
      productHand,
      product: {
        model: product.text,
        storage: storage.text,
        color: color.text,
      },
      productCode: productCode,
      period: period,
      combo: productCombo,
    };
    const res = await InstallMentApi.calculate(payload);
    if (res.status === 200) {
      setDownPayment(res.data.downPayment);
      setInstallMents(res.data.installments);
      setTotalPrice(res.data.totalPrice);

      amplitudeTrack(
        "1_Register_P_price",
        product +
          "," +
          storage +
          "," +
          period +
          "," +
          productCombo.map((e: any) => {
            return e.name + ",";
          })
      );
    }
  };

  // useEffect(() => {
  //   if (productCombo.length > 0) {
  //     let iPrice: number = 0
  //     for (const item of productCombo) {
  //       iPrice += item.down_amount
  //     }
  //     setDownPaymentAss(iPrice)
  //   }
  // }, [productCombo]);

  const clearData = () => {
    setProductHand("");
    setErrorMessage(initStateErrorMessage);
    setProuctGroup("iPhone");
    setProductModel([]);
    setProduct({ ...ININT_PROD });
    setStorageModel([]);
    setStorage({ ...ININT_PROD });
    setBranchData([]);
    setBranch("");
    setColorModel([]);
    setColor({ ...ININT_PROD });
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setDownPayment("0");
    // setDownPaymentAss(0)
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
  };

  const clearDataProductHand = () => {
    setProuctGroup("iPhone");
    setErrorMessage(initStateErrorMessage);
    setStorageModel([]);
    setColorModel([]);
    setProduct({ ...ININT_PROD });
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setBranch("");
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setDownPayment("0");
    // setDownPaymentAss(0)
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
  };

  const clearDataProductGroup = () => {
    setErrorMessage(initStateErrorMessage);
    setProductModel([]);
    setProduct({ ...ININT_PROD });
    setStorageModel([]);
    setStorage({ ...ININT_PROD });
    setBranch("");
    setColorModel([]);
    setColor({ ...ININT_PROD });
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setDownPayment("0");
    // setDownPaymentAss(0)
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
  };

  const clearDataProduct = () => {
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setInstallMents(0);
    setDownPayment("0");
    // setDownPaymentAss(0)
    setStorageModel([]);
    setColorModel([]);
    setPreiod(0);
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
  };

  const clearDataStorage = () => {
    setColor({ ...ININT_PROD });
    setInstallMents(0);
    setDownPayment("0");
    // setDownPaymentAss(0)
    setColorModel([]);
    setPreiod(0);
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
  };

  const clearDataColor = () => {
    setInstallMents(0);
    setDownPayment("0");
    setPreiod(0);
    setOptionsPeriodChoice([]);
    // setCheckedItems([])
    // setProductCombo([])
    // setProductComboData([])
    // setProductComboCheckboxData([])
  };

  const onSubmit = async () => {
    if (!product || !storage || !color || !period || !branch || (checkedItems.length > 0 && _.isEmpty(productCombo))) {
      const msg: any = {
        PRODUCT_MODEL: !product ? "โปรดเลือกสินค้า" : "",
        PRODUCT_STORAGE: !storage ? "โปรดเลือกความจุ" : "",
        PRODUCT_COLOR: !color ? "โปรดเลือกสี" : "",
        PRODUCT_PERIOD: !period ? "โปรดเลือกงวด" : "",
        PRODUCT_COMBO: checkedItems.length > 0 && _.isEmpty(productCombo) ? "โปรดเลือกสินค้า" : "",
        PRODUCT_BRANCH: !branch ? "โปรดเลือกสาขาที่สนใจ" : "",
      };
      setErrorMessage({ ...initStateErrorMessage, ...msg });
      return false;
    } else {
      // amplitudeTrack('1_Product_N_next')
      setData({
        ...data,
        product: {
          code: productCode,
          model: product,
          storage: storage,
          color: color,
        },
        productGroup: productGroup,
        productHand: productHand || "NEW",
        installment: installments,
        installments: installments,
        interestedBranch: branch,
        friend_advisor_code: friendAdvisorCode,
        combo: productCombo,
        period: period,
        downPayment: downPayment,
        totalPrice: totalPrice,
      });
      amplitudeTrack("1_Register_N_next");
      setTimeout(() => {
        return props.onClickNext && props.onClickNext();
      }, 500);
    }
  };

  const handleChange = (event: any, name: string) => {
    const { checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, { value: name }]);
    } else {
      setCheckedItems(checkedItems.filter((e: any) => e.value !== name));
      setProductCombo(productCombo.filter((e: any) => e.type !== name));
      // setDownPaymentAss(0)
    }
  };

  const handleChangeProdcuctCombo = (value: any, type: any, reason: any) => {
    const data = {
      name: value.name,
      code: value.code,
      down_amount: value.down_amount,
      type: type.value,
    };
    if (reason === "clear") {
      const data = productCombo.filter((i: any) => i.type !== type.value);
      setProductCombo(data);
      // setDownPaymentAss(0)
    } else {
    }
  };

  const onRegisterTypeChange = (type: string) => {
    const setDt: any = {
      ...data,
      type: type,
      profile: { ...data.profile, type: type },
      product: { code: "", model: { ...ININT_PROD }, storage: { ...ININT_PROD }, color: { ...ININT_PROD } },
      productGroup: "iPhone",
      productHand: "NEW",
      downPayment: "0",
      installment: 0,
      installments: 0,
      period: 0,
      interestedBranch: "",
      friend_advisor_code: "",
      combo: [],
    };

    setType(type);
    clearData();
    setData(setDt);

    amplitudeTrack(`${type === "INSTALLMENT" ? "1_Register_I_switch_to_lease" : "1_Register_I_switch_to_refin"}`);
  };

  return (
    <Styles.Container>
      <div>
        <RadioGroup
          aria-labelledby="controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          row
          value={type}
          onChange={(e: any) => onRegisterTypeChange(e.target.value)}
          className="justify-content-center"
        >
          {Object.entries(REGISTER_TYPE_TH).map(([key, name]) => (
            <Styles.TypeBox>
              <FormControlLabel
                key={key}
                value={key}
                control={
                  <Radio
                    sx={(theme) => ({
                      "& .MuiSvgIcon-root": {
                        [theme.breakpoints.down("xl")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("lg")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("md")]: {
                          fontSize: 30,
                        },
                      },
                    })}
                    disabled={PARAMS_TYPE === "DOWNPAYMENT"}
                  />
                }
                label={<div className="font-22 font-extra-bold">{name}</div>}
              />
            </Styles.TypeBox>
          ))}
        </RadioGroup>
      </div>

      <Styles.MarginRow>
        <Col lg={6} sm={6} xs={12}>
          <PreviewImage
            disabled={width <= 575 ? true : false}
            image={
              color
                ? _.find(colorModel, (val: any) => val.value === color)?.image
                : productModel
                ? _.find(productModel, (val: any) => val.value === product)?.image
                : ""
            }
          />
        </Col>
        <Col lg={6} sm={6} xs={12}>
          {type === "INSTALLMENT" && (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={productHand}
              onChange={(e: any) => {
                clearDataProductHand();
                setProductHand(e.target.value);
                // amplitudeTrack(`${e.target.value === 'INSTALLMENT' ? '1_Product_I_lease' : '1_Product_I_refin'}`, '')
              }}
            >
              <Col xs={6} md={6}>
                <FormControlLabel
                  value="NEW"
                  control={
                    <Radio
                      sx={(theme) => ({
                        "& .MuiSvgIcon-root": {
                          [theme.breakpoints.down("xl")]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down("lg")]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down("md")]: {
                            fontSize: 30,
                          },
                        },
                      })}
                    />
                  }
                  label="สินค้ามือหนึ่ง"
                />
              </Col>
              <Col xs={6} md={6}>
                <FormControlLabel
                  value="SECOND"
                  control={
                    <Radio
                      sx={(theme) => ({
                        "& .MuiSvgIcon-root": {
                          [theme.breakpoints.down("xl")]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down("lg")]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down("md")]: {
                            fontSize: 30,
                          },
                        },
                      })}
                      disabled={PARAMS_TYPE === "DOWNPAYMENT"}
                    />
                  }
                  label="สินค้ามือสอง"
                />
              </Col>
            </RadioGroup>
          )}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            row
            value={productGroup}
            onChange={(e: any) => {
              clearDataProductGroup();
              setProuctGroup(e.target.value);
            }}
          >
            <Col xs={6} md={6}>
              <FormControlLabel
                value="iPhone"
                control={
                  <Radio
                    sx={(theme) => ({
                      "& .MuiSvgIcon-root": {
                        [theme.breakpoints.down("xl")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("lg")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("md")]: {
                          fontSize: 30,
                        },
                      },
                    })}
                  />
                }
                label="iPhone"
              />
            </Col>
            <Col xs={6} md={6}>
              <FormControlLabel
                value="iPad"
                control={
                  <Radio
                    sx={(theme) => ({
                      "& .MuiSvgIcon-root": {
                        [theme.breakpoints.down("xl")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("lg")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("md")]: {
                          fontSize: 30,
                        },
                      },
                    })}
                  />
                }
                label="iPad"
              />
            </Col>
          </RadioGroup>
          <Styles.MarginRow>
            <AutocompleteSelect
              options={productModel || []}
              value={_.find(productModel, (val: any) => val.value === product.code) || null}
              placeholder={"เลือกสินค้า"}
              getOptionLabel={(option: any) => option.label}
              labelId="product"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_MODEL: "" });
                  setProduct({ code: value.value, text: value.label });
                  // amplitudeTrack(`${'1_Product_P_model-' + value.value}`, value.value)
                  clearDataProduct();
                } else if (reason === "clear") {
                  setProduct({ ...ININT_PROD });
                  clearDataProduct();
                }
              }}
              heading="สินค้า"
              helperText={errorMessage.PRODUCT_MODEL}
              required
            />
          </Styles.MarginRow>
          <Row>
            <Col xs={12} lg={6} className="pt-2">
              <AutocompleteSelect
                options={storageModel || []}
                value={_.find(storageModel, (val: any) => val.value === storage.code) || null}
                placeholder={"เลือกความจุ"}
                getOptionLabel={(option: any) => option.label}
                labelId="storage"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_STORAGE: "" });
                    setStorage({ code: value.value, text: value.label });
                    // amplitudeTrack(`${'1_Product_P_capacity'}`, value.value)
                    clearDataStorage();
                  } else if (reason === "clear") {
                    setStorage({ ...ININT_PROD });
                    clearDataStorage();
                  }
                }}
                heading="ความจุ"
                required
                helperText={errorMessage.PRODUCT_STORAGE}
              />
            </Col>
            <Col xs={12} lg={6} className="pt-2">
              <AutocompleteSelect
                options={colorModel || []}
                value={_.find(colorModel, (val: any) => val.value === color.code) || null}
                placeholder={"เลือกสี"}
                getOptionLabel={(option: any) => option.label}
                labelId="color"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_COLOR: "" });
                    // amplitudeTrack(`${'1_Product_P_color'}`, value.value)
                    setColor({ code: value.value, text: value.label });
                    clearDataColor();
                  } else if (reason === "clear") {
                    setColor({ ...ININT_PROD });
                    clearDataColor();
                  }
                }}
                heading="สี"
                required
                helperText={errorMessage.PRODUCT_COLOR}
              />
            </Col>
          </Row>

          <Styles.MarginRow>
            <div>
              <span>งวด</span>
              <span style={{ color: colors.danger }}>*</span>
            </div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={_.find(optionsPeriodChoice, (val: any) => val.value === period)?.value || null}
              onChange={(e: any) => {
                if (e) {
                  setErrorMessage({ ...errorMessage, PRODUCT_PERIOD: "" });
                  setPreiod(parseInt(e.target.value));
                  // amplitudeTrack(`${'1_Product_P_period'}`, e.target.value)
                }
              }}
            >
              {optionsPeriodChoice?.map((el: any, idx: number) => {
                return (
                  <FormControlLabel
                    value={el.value}
                    key={idx}
                    control={
                      <Radio
                        key={idx}
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label={el.label}
                  />
                );
              })}
            </RadioGroup>
            {errorMessage.PRODUCT_PERIOD !== "" && (
              <div>
                <HelperText label={errorMessage.PRODUCT_PERIOD} />
              </div>
            )}
          </Styles.MarginRow>

          {productCombCheckboxData?.length > 0 && (
            <Styles.MarginRow>
              <>
                <div>อุปกรณ์เสริม</div>
                {productCombCheckboxData.map((item: any) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            _.find(checkedItems, (val: any) => (val.value === item.values ? true : false)) || false
                          }
                          onChange={(e: any) => {
                            handleChange(e, item.values);
                          }}
                        />
                      }
                      label={item.values}
                    />
                  );
                })}
              </>
            </Styles.MarginRow>
          )}
          {checkedItems &&
            checkedItems.map((e: any) => {
              return (
                <Styles.MarginRow>
                  <AutocompleteSelect
                    options={productComboData[e.value] || []}
                    value={
                      _.find(
                        productComboData[e.value],
                        (val: any) => _.find(productCombo, (e: any) => e.name === val.name) || null
                      ) || null
                    }
                    placeholder={"เลือก Pencil"}
                    getOptionLabel={(option: any) => option.name}
                    labelId="contracttype"
                    onChange={(event, value, reason) => {
                      if (value) {
                        const data = {
                          name: value.name,
                          code: value.code,
                          down_amount: value.down_amount,
                          type: e.value,
                        };
                        setErrorMessage({ ...errorMessage, PRODUCT_COMBO: "" });
                        // productCombo.filter((i: any) => i.code !== value.code)
                        // setProductCombo(productCombo.filter((i: any) => i.code !== value.code))
                        setProductCombo([data]);
                      } else if (reason === "clear") {
                        setProductCombo(productCombo.filter((i: any) => i.type !== e.value));
                        // setDownPaymentAss(0)
                      }
                    }}
                    heading={e.value}
                    helperText={errorMessage.PRODUCT_COMBO}
                    required
                  />
                </Styles.MarginRow>
              );
            })}

          <Styles.MarginRow>
            <Col xs={12} lg={6} className="mt-2">
              <InputTextField
                value={numberFormat(Number(downPayment))}
                // value={numberFormat(Number(downPayment) + Number(downPaymentAss) || Number(max))}
                heading={"จำนวนเงินดาวน์"}
                disabled
              />
            </Col>
            <Col xs={12} lg={6} className="mt-2">
              <InputTextField value={numberFormat(installments)} heading="จำนวนเงินผ่อน" disabled />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <AutocompleteSelect
              options={branchData || []}
              value={_.find(branchData, (val: any) => val.value === branch) || null}
              placeholder={"เลือกสาขา"}
              groupBy={(option: any) => option.region}
              getOptionLabel={(option: any) => option.label}
              labelId="contracttype"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_BRANCH: "" });
                  setBranch(value.value);
                  amplitudeTrack(`${"1_Register_P_interestedBranch"}`, value.value);
                } else if (reason === "clear") setBranch("");
              }}
              heading="สาขาที่สนใจ"
              helperText={errorMessage.PRODUCT_BRANCH}
              required
            />
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col xs={12}>
              <InputTextField
                value={friendAdvisorCode}
                onchange={(event: any) => {
                  setFriendAdvisorCode(event.target.value);
                }}
                onBlur={() => {
                  if (friendAdvisorCode) amplitudeTrack("1_Register_P_fgf", friendAdvisorCode);
                }}
                heading="รหัสผู้แนะนำ (ถ้ามี)"
              />
            </Col>
          </Styles.MarginRow>

          <Styles.CenterContainer>
            <ButtonCustom
              btnStyle={{ width: "100%" }}
              className={"mt-2"}
              textButton={"ยืนยัน"}
              onClick={() => {
                onSubmit();
              }}
            />
          </Styles.CenterContainer>
        </Col>
      </Styles.MarginRow>
    </Styles.Container>
  );
}
