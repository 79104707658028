import { REGISTER_TYPE } from "../../utils/enum";

const { INSTALLMENT, TURN1, TURN2, SECONDHAND } = REGISTER_TYPE

export const defaultDataRegister =
{
    productHand: 'NEW',
    productGroup: 'iPhone',
    acceptCondition: false,
    walkin: false,
    job: {
        type: 'บุคคลทั่วไป'
    },
    register_web_version: process.env.REACT_APP_WEB_VERSION,
    type: TURN1,
}

export const defaultDataRegisterInstallMent =
{
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "job": {
        "type": "บุคคลทั่วไป",
        "occupation": "พนักงานเงินเดือน",
        "business": "พนักงานโรงแรม/พนักงานต้อนรับ",
        "companyName": "เทส",
        "companyLocation": "เทส",
        "position": "เทส",
        "experience": "2 ปีขึ้นไป",
        "companyPhoneNumber": "09943849389434",
        "salary": "22500"
    },
    "register_web_version": "3.1.3",
    "type": "INSTALLMENT",
    "product": {
        "code": "G01101080142",
        "model": {
            "code": "014",
            "text": "iPhone 13"
        },
        "storage": {
            "code": "2",
            "text": "128GB"
        },
        "color": {
            "code": "08",
            "text": "Green"
        }
    },
    "downPayment": "7900.00",
    "installments": "2340.00",
    "period": 6,
    "interestedBranch": "CENTER",
    "friend_advisor_code": "",
    "installment": "2340.00",
    "combo": [],
    "profile": {
        "type": "INSTALLMENT",
        "idCard": "2507406472198",
        "phoneNumber": "0998493894",
        "prefix": "นาย",
        "firstName": "เทส",
        "lastName": "เทส",
        "address": "ที่อยู่อาศัยปัจจุบัน",
        "zipcode": "10120",
        "subdistrict": "บางโคล่",
        "district": "บางคอแหลม",
        "province": "กรุงเทพมหานคร",
        "facebook": "facebook",
        "phoneSystem": "เติมเงิน",
        "dateOfBirth": "01/01/2001"
    },
    "statement": {
        "images": [
            {
                "fileName": "20250203-a80c4e74-bc37-496a-8031-8e85a7a98b5d.png",
                "mimeType": "image/png",
                "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e51e3506a86ecd9d7f501c61e220d3cebba9e15a0ca059d722e97285a6ee675cbb0151c657f1973f7a20d9337030f87ccf"
            }
        ],
        "type": "CURRENT",
        "month": "",
        "password": ""
    },
    "proveWork": [],
    "riderAppication": [],
    "governmentOfficial": [],
    "proveOwnBusinessImage": [],
    "salesChannelsImage": {
        "images": [],
        "link": ""
    },
    "contact": [
        {
            "relationship": "พ่อแม่",
            "firstName": "พ่อ",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0993849839"
        }
    ],
    "knownUfriendFrom": {
        "value": "TIKTOK",
        "label": "Tiktok"
    },
    "othersDetails": ""
}

export const defaultDataRegisterTurn1 = {
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "job": {
        "type": "บุคคลทั่วไป",
        "occupation": "พนักงานเงินเดือน",
        "business": "พนักงานโรงแรม/พนักงานต้อนรับ",
        "companyName": "dev",
        "companyLocation": "เทส",
        "position": "เทส",
        "experience": "2 ปีขึ้นไป",
        "companyPhoneNumber": "1234",
        "salary": "27500"
    },
    "register_web_version": "3.1.3",
    "type": "TURN1",
    "estimate":{
        "battery": [
            "BAT002"
        ],
        "defect": [
            "DEF002",
            "DEF009",
            "DEF001"
        ],
        "model": [
            "MOD001"
        ],
        "accessory": [
            "ACC002"
        ],
        "coverage": [
            "COV001"
        ],
        "deviceStatus": [
            "DIS001",
            "SIDE001"
        ]
    },
    "estimateValue":[
        {
            "code": "MOD001",
            "value": "C1"
        },
        {
            "code": "BAT002",
            "value": "C1"
        },
        {
            "code": "ACC002",
            "value": "C2"
        },
        {
            "code": "COV001",
            "value": "C1"
        },
        {
            "code": "DIS001",
            "value": "C1"
        },
        {
            "code": "SIDE001",
            "value": "C1"
        },
        {
            "code": "DEF001",
            "value": "C5"
        }
    ],
    "product": {
        "code": "G01301080142",
        "model": {
            "code": "014",
            "text": "iPhone 13"
        },
        "storage": {
            "code": "2",
            "text": "128GB"
        },
        "color": {
            "code": "08",
            "text": "Green"
        }
    },
    "condition": "C0",
    "maximum": 33000,
    "downPayment": "2500.00",
    "installment": "3000.00",
    "installments": "3000.00",
    "period": 6,
    "interestedBranch": "CENTER",
    "combo": [],
    "discountPeriod": {
        "label": "ส่วนลดค่าเครื่อง:2000",
        "code": "DISPERIOD2000",
        "discount": "2000"
    },
    "coupon": [
        {
            "coupon_id": "PON-25-0006",
            "coupon_code": "REGISTERCOUPON",
            "coupon_discount": 200
        }
    ],
    "coupon_discount": 200,
    "profile": {
        "type": "TURN1",
        "idCard": "6406155370627",
        "phoneNumber": "0989483943",
        "prefix": "นาย",
        "firstName": "เทส",
        "lastName": "เทส",
        "address": "ที่อยู่อาศัยปัจจุบัน",
        "zipcode": "10120",
        "subdistrict": "บางคอแหลม",
        "district": "บางคอแหลม",
        "province": "กรุงเทพมหานคร",
        "facebook": "facebook",
        "phoneSystem": "เติมเงิน",
        "dateOfBirth": "01/01/2001",
        "serial_number": "12345",
        "imeiImages": {
            "type": "TURN1",
            "images": [
                {
                    "fileName": "20250203-c809ca87-d5ce-4600-9639-6bbe4ee07f2d.png",
                    "mimeType": "image/png",
                    "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e5c4142fc15a92557d3e50794e807e0a61962900f15fe9afe15793367c6bc2627ee417f99ad60f0a3e3b4047a29de74009"
                }
            ]
        },
        "iphoneStorage": {
            "type": "TURN1",
            "images": [
                {
                    "fileName": "20250203-914613d8-48c2-4109-a8e9-6aa5ec676a10.png",
                    "mimeType": "image/png",
                    "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e5001866d99218220565289176f47a5322b412c4200083661602d02cc46d84c958111b8e9309d93126b18c8c94f0b8e242"
                }
            ]
        }
    },
    "statement": {
        "images": [
            {
                "fileName": "20250203-0734caac-5f0b-46d8-a444-b582ae3aa65c.png",
                "mimeType": "image/png",
                "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e50b4ff38ce87e66b2d0ebcbe018a2d9a2262914d2f9482a757baabf5ba8245259f0ef684f46990256a15a15a023cff851"
            }
        ],
        "type": "CURRENT",
        "month": "",
        "password": ""
    },
    "proveWork": [],
    "riderAppication": [],
    "governmentOfficial": [],
    "proveOwnBusinessImage": [],
    "salesChannelsImage": {
        "images": [],
        "link": ""
    },
    "contact": [
        {
            "relationship": "พ่อแม่",
            "firstName": "พ่อ",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0939489343"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "แม่",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0987348738"
        },
        {
            "relationship": "พี่น้อง",
            "firstName": "พี่",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0983748347"
        }
    ],
    "knownUfriendFrom": {
        "value": "FACEBOOK",
        "label": "Facebook"
    },
    "othersDetails": ""
}

export const defaultDataRegisterTurn2 = {
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "job": {
        "type": "บุคคลทั่วไป",
        "occupation": "พนักงานเงินเดือน",
        "business": "ผู้จัดการสาขา/โรงแรม/ร้านค้า",
        "companyName": "เทส",
        "companyLocation": "เทส",
        "position": "เทส",
        "experience": "2 ปีขึ้นไป",
        "companyPhoneNumber": "09493849384934",
        "salary": "35000"
    },
    "register_web_version": "3.1.3",
    "type": "TURN1",
    "estimate":{
        "battery": [
            "BAT002"
        ],
        "defect": [
            "DEF002",
            "DEF009",
            "DEF001"
        ],
        "model": [
            "MOD001"
        ],
        "accessory": [
            "ACC002"
        ],
        "coverage": [
            "COV001"
        ],
        "deviceStatus": [
            "DIS001",
            "SIDE001"
        ]
    },
    "estimateValue":[
        {
            "code": "MOD001",
            "value": "C1"
        },
        {
            "code": "BAT002",
            "value": "C1"
        },
        {
            "code": "ACC002",
            "value": "C2"
        },
        {
            "code": "COV001",
            "value": "C1"
        },
        {
            "code": "DIS001",
            "value": "C1"
        },
        {
            "code": "SIDE001",
            "value": "C1"
        },
        {
            "code": "DEF001",
            "value": "C5"
        }
    ],
    "productTurn": {
        "code": "G01301080142",
        "model": {
            "code": "014",
            "text": "iPhone 13"
        },
        "storage": {
            "code": "2",
            "text": "128GB"
        },
        "color": {
            "code": "08",
            "text": "Green"
        }
    },
    "product": {
        "code": "G01101200142",
        "model": {
            "code": "014",
            "text": "iPhone 13"
        },
        "storage": {
            "code": "2",
            "text": "128GB"
        },
        "color": {
            "code": "20",
            "text": "Midnight"
        }
    },
    "condition": "C3",
    "maximum": "10000.00",
    "installment": "1500.00",
    "installments": "1500.00",
    "period": 10,
    "interestedBranch": "CENTER",
    "combo": [],
    "discountPeriod": {
        "label": "ส่วนลดค่าเครื่อง:1000",
        "code": "DISPERIOD1000",
        "discount": "1000"
    },
    "coupon": [
        {
            "coupon_id": "PON-25-0006",
            "coupon_code": "REGISTERCOUPON",
            "coupon_discount": 200
        }
    ],
    "coupon_discount": 200,
    "profile": {
        "type": "TURN2",
        "idCard": "9586736406438",
        "phoneNumber": "0939493849",
        "prefix": "นาย",
        "firstName": "เทส",
        "lastName": "เทส",
        "address": "ที่อยู่อาศัยปัจจุบัน",
        "zipcode": "10120",
        "subdistrict": "วังใหม่",
        "district": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "facebook": "Facebook",
        "phoneSystem": "เติมเงิน",
        "dateOfBirth": "01/01/1997",
        "serial_number": "1234",
        "imeiImages": {
            "type": "TURN2",
            "images": [
                {
                    "fileName": "20250203-6e6cc689-c5b5-414d-9d30-747500074ea1.png",
                    "mimeType": "image/png",
                    "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e5af4554fcb60534bb7c3329b30cc3511666585dc2374a3ef36a780bb8698395ca477cca90e9cfc7ba042249bc45f9e8e6"
                }
            ]
        },
        "iphoneStorage": {
            "type": "TURN2",
            "images": [
                {
                    "fileName": "20250203-aa91f91d-38b1-4919-9529-f79f0b727b8a.png",
                    "mimeType": "image/png",
                    "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e548b6ccd72c83b84b7f91dcbc6a0b316f97caab73572719fcca1e61c6dcf9d335ad890a157d028323b60dc59506353f74"
                }
            ]
        }
    },
    "statement": {
        "images": [
            {
                "fileName": "20250203-3a47cb71-75b0-4748-85b1-4750f90a41dd.png",
                "mimeType": "image/png",
                "url": "https://dev2-admin-api.ufdev.link/v2/files/pri/temp/d7b9081a776996a16307253022a21b4ee6e5b289c9fd5cd119011c46e8455c66243b9edcbad5b1076278e6f733ada4e51ab92c1dd20e12907bed6933c3d8bb3010f07e552f80b118bb440151e4e7a6ac962073a7c789c01383b01f28af191e7b"
            }
        ],
        "type": "CURRENT",
        "month": "",
        "password": ""
    },
    "proveWork": [],
    "riderAppication": [],
    "governmentOfficial": [],
    "proveOwnBusinessImage": [],
    "salesChannelsImage": {
        "images": [],
        "link": ""
    },
    "contact": [
        {
            "relationship": "พ่อแม่",
            "firstName": "พ่อ",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0834983948"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "แม่",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0993484883"
        },
        {
            "relationship": "พี่น้อง",
            "firstName": "พี่",
            "lastName": "นามสมมุติ",
            "phoneNumber": "0938478374"
        }
    ],
    "knownUfriendFrom": {
        "value": "FACEBOOK",
        "label": "Facebook"
    },
    "othersDetails": ""
}

export const defaultDataRegisterSeconhand = {
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "job": {
        "type": "บุคคลทั่วไป"
    },
    "register_web_version": "3.1.3",
    "type": "SECONDHAND",
    "product": {
        "code": "G01301210142",
        "model": {
            "code": "014",
            "text": "iPhone 13"
        },
        "storage": {
            "code": "2",
            "text": "128GB"
        },
        "color": {
            "code": "21",
            "text": "Pink"
        }
    },
    "downPayment": "0",
    "installment": 0,
    "installments": 0,
    "period": 0,
    "interestedBranch": "CENTER",
    "friend_advisor_code": "",
    "estimate":{
        "battery": [
            "BAT002"
        ],
        "defect": [
            "DEF002",
            "DEF009",
            "DEF001"
        ],
        "model": [
            "MOD001"
        ],
        "accessory": [
            "ACC002"
        ],
        "coverage": [
            "COV001"
        ],
        "deviceStatus": [
            "DIS001",
            "SIDE001"
        ]
    },
    "estimateValue":[
        {
            "code": "MOD001",
            "value": "C1"
        },
        {
            "code": "BAT002",
            "value": "C1"
        },
        {
            "code": "ACC002",
            "value": "C2"
        },
        {
            "code": "COV001",
            "value": "C1"
        },
        {
            "code": "DIS001",
            "value": "C1"
        },
        {
            "code": "SIDE001",
            "value": "C1"
        },
        {
            "code": "DEF001",
            "value": "C5"
        }
    ],
    "condition": "C3",
    "maximum": "10000.00",
    "appointmentDate": "2025-02-13T10:20:54.000Z",
    "profile": {
        "type": "SECONDHAND",
        "idCard": "6351887472394",
        "phoneNumber": "0943849384",
        "prefix": "นาย",
        "firstName": "เทส",
        "lastName": "เทส",
        "address": "ที่อยู่อาศัยปัจจุบัน",
        "zipcode": "10120",
        "subdistrict": "บางโคล่",
        "district": "บางคอแหลม",
        "province": "กรุงเทพมหานคร",
        "dateOfBirth": "01/01/1997"
    }
}