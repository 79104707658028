import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import OptionsApi from "../../../api/option/option.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import PreviewImage from "../../../components/image/previewImage";
import InputTextField from "../../../components/input/inputTextField";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { numberFormat } from "../../../utils/common";
import * as Styles from "./layout-product.style";
import useWindowSize from "../../../utils/useWindowSize";
import { REGISTER_BRANCH_TYPE, REGISTER_TYPE } from "../../../utils/enum";
import { RESPONSIVE_FULL, RESPONSIVE_HALF_XS_FULL } from "../constants/constants";
import InputDatePicker from "../../../components/input/inputDatePicker";
import { FromRegister, Product } from "../type-register";

const initStateErrorMessage = {
  PRODUCT_BRANCH: ``,
  SERVICE_DATE: ``,
};

const { SECOND } = REGISTER_BRANCH_TYPE;
const { SECONDHAND } = REGISTER_TYPE;

const ININT_PROD = { code: "", text: "" };

interface props {
  data?: any;
  onClickNext: () => void;
  onClickBack: () => void;
  onChange?: (value: any) => void;
}
export default function LayoutProductSecondhand(props: props) {
  const [width] = useWindowSize();
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [type, setType] = useState<string>(props.data?.type || SECONDHAND);
  const [data, setData] = useState<FromRegister>(props.data);
  const [productGroup, setProductGroup] = useState<string>(props.data?.productGroup || "iPhone");
  const [productHand, setProductHand] = useState<string>(props.data?.productHand || SECOND);
  const [productModel, setProductModel] = useState<any[]>([]);
  const [productCode, setProductCode] = useState<string>(props.data?.product?.code || "");
  const [product, setProduct] = useState<Product>(props.data?.product?.model || { ...ININT_PROD });
  const [storageModel, setStorageModel] = useState<any[]>([]);
  const [storage, setStorage] = useState<Product>(props.data?.product?.storage || { ...ININT_PROD });
  const [colorModel, setColorModel] = useState<any[]>([]);
  const [color, setColor] = useState<Product>(props.data?.product?.color || { ...ININT_PROD });

  const [maximum, setMaximum] = useState<number>(props.data?.maximum || 0);

  const [branchData, setBranchData] = useState<any[]>([]);
  const [branch, setBranch] = useState<string>(props.data?.interestedBranch);
  const [appointmentDate, setAppointmentDate] = useState<any>(
    props.data?.appointmentDate ? moment(props.data?.appointmentDate) : ""
  );

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data);
    }
  }, [data]);

  const loadProduct = useCallback(() => {
    let condition: any = {};
    if (type) condition = { ...condition, type: SECONDHAND };
    if (productGroup) condition = { ...condition, category: productGroup === "iPhone" ? "Smartphone" : "Tablet" };
    if (productHand) condition = { ...condition, productHand: productHand };
    OptionsApi.productModel(condition).then((res) => {
      if (res.status === 200) {
        setProductModel(res.data.model);
      }
    });
  }, [type, productGroup, productHand]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const loadBranch = useCallback(() => {
    let condition: any = {};
    if (type) {
      condition = { ...condition, type };
    }
    OptionsApi.registerBranch(condition).then((res) => {
      if (res.status === 200) {
        setBranchData(res.data.result);
      }
    });
  }, [type]);

  useEffect(() => {
    loadBranch();
  }, [loadBranch]);

  useEffect(() => {
    if (product.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: SECONDHAND };
      if (product.text) condition = { ...condition, model: product.text };
      if (productHand) condition = { ...condition, productHand: productHand };
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageModel(res.data.storage);
        }
      });
    }
  }, [product.text]);

  useEffect(() => {
    if (type && product.text && storage.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: SECONDHAND };
      if (product.text) condition = { ...condition, model: product.text };
      if (storage.text) condition = { ...condition, storage: storage.text };
      if (productHand) condition = { ...condition, productHand: productHand };
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorModel(res.data.color);
        }
      });
    }
  }, [storage.text]);

  const clearData = () => {
    setErrorMessage(initStateErrorMessage);
    setProductModel([]);
    setProduct({ ...ININT_PROD });
    setStorageModel([]);
    setStorage({ ...ININT_PROD });
    setBranchData([]);
    setBranch("");
    setColorModel([]);
    setColor({ ...ININT_PROD });
    setMaximum(0);
    setProductCode("");
    setProductGroup("iPhone");
    setProductHand("NEW");
  };

  const onSubmit = async () => {
    if (!product.code || !storage.code || !color.code || !branch) {
      const msg: any = {
        PRODUCT_BRANCH: !branch ? "โปรดเลือกสาขาที่สนใจ" : "",
        SERVICE_DATE: !appointmentDate ? "โปรดเลือกวันที่ต้องการรับบริการ" : "",
      };
      setErrorMessage({ ...initStateErrorMessage, ...msg });
      return false;
    } else {
      setData({
        ...data,
        interestedBranch: branch,
        appointmentDate: appointmentDate,
      });
      setTimeout(() => {
        return props.onClickNext && props.onClickNext();
      }, 500);
    }
  };

  const onClickBack = async () => {
    const setDt = {
      ...data,
      product: { ...data.product, productCode },
      type: SECONDHAND,
      productGroup: productGroup,
      productHand: SECOND,
      interestedBranch: branch,
      appointmentDate: appointmentDate,
    };

    clearData();
    setData(setDt);

    setTimeout(() => {
      return props.onClickBack && props.onClickBack();
    }, 500);
  };

  return (
    <Styles.Container>
      <Styles.MarginRow>
        <Col {...RESPONSIVE_HALF_XS_FULL}>
          <PreviewImage
            disabled={width <= 575 ? true : false}
            image={
              color
                ? _.find(colorModel, (val: any) => val.value === color)?.image
                : productModel
                ? _.find(productModel, (val: any) => val.value === product)?.image
                : ""
            }
          />
        </Col>
        <Col {...RESPONSIVE_HALF_XS_FULL}>
          <Row>
            <Col {...RESPONSIVE_FULL} className="pt-2">
              <AutocompleteSelect
                options={productModel || []}
                value={_.find(productModel, (val: any) => val.value === product.code) || null}
                placeholder={"เลือกสินค้า"}
                getOptionLabel={(option: any) => option.label}
                labelId="product"
                heading="สินค้า"
                required
                disabled={true}
              />
            </Col>
          </Row>

          <Row>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={storageModel || []}
                value={_.find(storageModel, (val: any) => val.value === storage.code) || null}
                placeholder={"เลือกความจุ"}
                getOptionLabel={(option: any) => option.label}
                labelId="storage"
                heading="ความจุ"
                required
                disabled={true}
              />
            </Col>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={colorModel || []}
                value={_.find(colorModel, (val: any) => val.value === color.code) || null}
                placeholder={"เลือกสี"}
                getOptionLabel={(option: any) => option.label}
                labelId="color"
                heading="สี"
                required
                disabled={true}
              />
            </Col>
          </Row>

          <Row>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <InputTextField value={numberFormat(maximum)} heading="ราคาสูงสุด" disabled />
            </Col>
          </Row>

          <Styles.MarginRow>
            <AutocompleteSelect
              options={branchData || []}
              value={_.find(branchData, (val: any) => val.value === branch) || null}
              placeholder={"เลือกสาขา"}
              groupBy={(option: any) => option.region}
              getOptionLabel={(option: any) => option.label}
              labelId="interestedBranch"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_BRANCH: "" });
                  setBranch(value.value);
                } else if (reason === "clear") setBranch("");
              }}
              heading="สาขาที่สนใจ"
              helperText={errorMessage.PRODUCT_BRANCH}
              required
            />
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col {...RESPONSIVE_FULL}>
              <InputDatePicker
                required
                disablePast
                value={appointmentDate}
                allowClear
                onClear={() => setAppointmentDate("")}
                key={"date"}
                onChange={(e: any) => {
                  setAppointmentDate(e);
                  setErrorMessage({ ...errorMessage, SERVICE_DATE: "" });
                }}
                helperText={errorMessage.SERVICE_DATE}
                heading={"กรุณาเลือกวันที่ ที่ต้องการเข้ารับบริการ"}
              />
            </Col>
          </Styles.MarginRow>

          <Styles.MarginRow>
            <Col className="pt-2">
              <ButtonCustom
                variant={"outline"}
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ย้อนกลับ"}
                onClick={() => {
                  onClickBack();
                }}
              />
            </Col>
            <Col className="pt-2">
              <ButtonCustom
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ถัดไป"}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Col>
          </Styles.MarginRow>
        </Col>
      </Styles.MarginRow>
    </Styles.Container>
  );
}
