import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import * as Inf from "../interface/estimate.interfaces";
import ButtonCustom from "../../../../components/button/buttonCustom";

const EstimateOptionsGroup = (props: Inf.EstimateOptionsGroupProps) => {
  const RESPONSIVE = {
    xs: 12,
    sm: 6,
    lg: 4,
    xl: 4,
    xxl: 4,
  };

  const { data, multiple, onSelect, selectedCode, subKeyLength = 0, estimateValue, setEstimateValue } = props;

  const handleSelect = (opt: Inf.SubKey) => {
    let newItemCode: string[] = [];
    let newItemName: string = "";
    let expand = true;

    const hasSelected = _.includes(selectedCode, opt?.code);

    if (multiple) {
      newItemCode = hasSelected
        ? _.filter(selectedCode, (optCode) => optCode !== opt?.code)
        : _.union(selectedCode, [opt.code]);
      newItemName = `มี ${newItemCode.length} ข้อ`;
      expand = false;
    } else if (subKeyLength > 1) {
      const temp = [...selectedCode];
      const prefix = _.slice(opt?.code, 0, 3).join("");
      const index = _.findIndex(selectedCode, (item) => item.includes(prefix));

      if (index !== -1) {
        _.pullAt(temp, index); // ลบ element ที่ตำแหน่ง index
        newItemCode = [...temp, opt?.code];
        newItemName = opt?.name;
      } else {
        newItemCode = [...selectedCode, opt?.code];
        newItemName = opt?.name;
      }
      if (newItemCode.length !== subKeyLength) expand = false;
    } else {
      newItemCode = hasSelected ? [] : [opt?.code];
      newItemName = hasSelected ? "" : opt?.name;
    }

    if (hasSelected) {
      const estValue = estimateValue.filter((item: any) => {
        if (item.code !== opt?.code) {
          return item;
        }
      });
      console.log("estValue: ", estValue);

      setEstimateValue(estValue);
    } else {
      setEstimateValue([...estimateValue, { code: opt.code, value: opt.value }]);
    }

    onSelect({ code: newItemCode, name: newItemName, expand: expand });
  };

  return (
    <Row>
      {_.map(data, (item: Inf.SubKey, index: number) => (
        <Col {...RESPONSIVE} className="pt-2">
          <ButtonCustom
            key={item?.code || index}
            className={_.includes(selectedCode, item?.code) ? "btn-estimate-selected" : `btn-estimate`}
            variant={"outlined"}
            onClick={() => handleSelect(item)}
            btnStyle={{
              fontWeight: "bold",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 16px !important",
            }}
            textButton={item?.name}
          />
        </Col>
      ))}
    </Row>
  );
};

export default EstimateOptionsGroup;
