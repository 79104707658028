import { useCallback, useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import InstallMentApi from "../../../api/installment/installment.api";
import OptionsApi from "../../../api/option/option.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import PreviewImage from "../../../components/image/previewImage";
import HelperText from "../../../components/input/helperText";
import InputTextField from "../../../components/input/inputTextField";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { colors } from "../../../constants/colors";
import { numberFormat } from "../../../utils/common";
import { CouponRes, FromRegister, Product } from "../type-register";
import * as Styles from "./layout-product.style";
import useWindowSize from "../../../utils/useWindowSize";
import { REGISTER_TYPE } from "../../../utils/enum";
import CouponInput from "../components/coupon";
import { RESPONSIVE_FULL, RESPONSIVE_HALF_XS_FULL } from "../constants/constants";
import EstimateText from "../components/estimate-text";

const initStateErrorMessage = {
  PRODUCT_MODEL: ``,
  PRODUCT_STORAGE: ``,
  PRODUCT_COLOR: ``,
  PRODUCT_PERIOD: ``,
  PRODUCT_BRANCH: ``,
  COUPON: ``,
};

const { TURN1 } = REGISTER_TYPE;

const ININT_PROD = { code: "", text: "" };

interface props {
  data?: any;
  onClickNext: () => void;
  onClickBack: () => void;
  onChange?: (value: any) => void;
  optionsPeriodChoice: any[];
  setOptionsPeriodChoice: (value: any[]) => void;
}
export default function LayoutProductTurn1(props: props) {
  const { optionsPeriodChoice, setOptionsPeriodChoice } = props;

  const [width] = useWindowSize();
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [type, setType] = useState<string>(props.data?.type || TURN1);
  const [data, setData] = useState<FromRegister>(props.data);
  const [productGroup, setProductGroup] = useState<string>(props.data?.productGroup || "iPhone");
  const [productModel, setProductModel] = useState<any[]>([]);
  const [productCode, setProductCode] = useState<string>(props.data?.product?.code || "");
  const [product, setProduct] = useState<Product>(props.data?.product?.model || { ...ININT_PROD });
  const [storageModel, setStorageModel] = useState<any[]>([]);
  const [storage, setStorage] = useState<Product>(props.data?.product?.storage || { ...ININT_PROD });
  const [colorModel, setColorModel] = useState<any[]>([]);
  const [color, setColor] = useState<Product>(props.data?.product?.color || { ...ININT_PROD });

  const [installments, setInstallMents] = useState<number>(0);
  const [period, setPreiod] = useState<number>(props.data?.period || 0);
  const [downPayment, setDownPayment] = useState<string>(props.data?.downPayment || "0");
  const [maximum, setMaximum] = useState<number>(props.data?.maximum || 0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [branchData, setBranchData] = useState<any[]>([]);
  const [branch, setBranch] = useState<string>(props.data?.interestedBranch);
  const [friendAdvisorCode, setFriendAdvisorCode] = useState<string>(props.data?.friend_advisor_code);

  const [estimateCondition, setEstimateCondition] = useState<string>(props.data?.condition || "");
  const [discountPeriod, setDiscountPeriod] = useState<any>(props.data?.discountPeriod || {});
  const [discountTurn1, setDiscountTurn1] = useState<any>(props.data?.discountTurn1 || {});
  const [coupon, setCoupon] = useState<CouponRes[]>(props.data?.coupon || []);
  const [couponDiscount, setCouponDiscount] = useState<number>(props.data?.coupon_discount || 0);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data);
    }
  }, [data]);

  const loadProduct = useCallback(() => {
    let condition: any = {};
    if (type) condition = { ...condition, type: TURN1 };
    if (productGroup) condition = { ...condition, category: productGroup === "iPhone" ? "Smartphone" : "Tablet" };
    OptionsApi.productModel(condition).then((res) => {
      if (res.status === 200) {
        setProductModel(res.data.model);
      }
    });
  }, [type, productGroup]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const loadBranch = useCallback(() => {
    let condition: any = {};
    if (type) {
      condition = { ...condition, type };
    }
    OptionsApi.registerBranch(condition).then((res) => {
      if (res.status === 200) {
        setBranchData(res.data.result);
      }
    });
  }, [type]);

  useEffect(() => {
    loadBranch();
  }, [loadBranch]);

  useEffect(() => {
    if (product.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN1 };
      if (product.text) condition = { ...condition, model: product.text };
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageModel(res.data.storage);
        }
      });
    }
  }, [product.text]);

  useEffect(() => {
    if (type && product.text && storage.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN1 };
      if (product) condition = { ...condition, model: product.text };
      if (storage) condition = { ...condition, storage: storage.text };
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorModel(res.data.color);
        }
      });
    }
  }, [storage.text]);

  useEffect(() => {
    if (period && product.code && storage.code && color.code && productCode && period && estimateCondition) {
      calculate();
    }
  }, [period, couponDiscount]);

  useEffect(() => {
    const sumCouponDiscount = _.sumBy(coupon, "coupon_discount");
    setCouponDiscount(sumCouponDiscount);
  }, [coupon]);

  const calculate = async () => {
    const payload = {
      type: TURN1,
      product: {
        model: product.text,
        storage: storage.text,
        color: color.text,
      },
      productCode: productCode,
      period: period,
      condition: estimateCondition,
      combo: [],
      coupon_discount: couponDiscount,
    };
    const res = await InstallMentApi.calculate(payload);

    if (res.status === 200) {
      setDiscountPeriod(res?.data?.discountPeriod);
      setDiscountTurn1(res?.data?.discountTurn1);
      setDownPayment(res.data.downPayment);
      setInstallMents(res.data.installments);
      setTotalPrice(res.data.totalPrice);
    }
  };

  const clearData = () => {
    setType(TURN1);
    setErrorMessage(initStateErrorMessage);
    setProductGroup("iPhone");
    setBranchData([]);
    setProductModel([]);
    setStorageModel([]);
    setColorModel([]);
    setOptionsPeriodChoice([]);
    setProduct({ ...ININT_PROD });
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setBranch("");
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setMaximum(0);
    setDownPayment("0");
    setProductCode("");
    setEstimateCondition("");
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataProduct = () => {
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setInstallMents(0);
    setDownPayment("0");
    setStorageModel([]);
    setColorModel([]);
    setPreiod(0);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataStorage = () => {
    setColor({ ...ININT_PROD });
    setInstallMents(0);
    setDownPayment("0");
    setColorModel([]);
    setPreiod(0);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataColor = () => {
    setInstallMents(0);
    setDownPayment("0");
    setPreiod(0);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const onSubmit = async () => {
    if (!product || !storage || !color || !period || !branch) {
      const msg: any = {
        PRODUCT_MODEL: !product.code ? "โปรดเลือกสินค้า" : "",
        PRODUCT_STORAGE: !storage.code ? "โปรดเลือกความจุ" : "",
        PRODUCT_COLOR: !color.code ? "โปรดเลือกสี" : "",
        PRODUCT_PERIOD: !period ? "โปรดเลือกงวด" : "",
        PRODUCT_BRANCH: !branch ? "โปรดเลือกสาขาที่สนใจ" : "",
      };
      setErrorMessage({ ...initStateErrorMessage, ...msg });
      return false;
    } else {
      setData({
        ...data,
        product: {
          code: productCode,
          model: product,
          storage: storage,
          color: color,
        },
        productGroup: productGroup,
        downPayment: downPayment,
        installment: installments,
        installments: installments,
        period: period,
        interestedBranch: branch,
        friend_advisor_code: friendAdvisorCode,
        combo: [],
        discountPeriod: discountPeriod,
        discountTurn1: discountTurn1,
        coupon: coupon,
        coupon_discount: couponDiscount,
        totalPrice: totalPrice,
      });
      setTimeout(() => {
        return props.onClickNext && props.onClickNext();
      }, 500);
    }
  };

  const onClickBack = async () => {
    const setDt: any = _.omit(
      {
        ...data,
        type: TURN1,
        product: {
          code: productCode,
          model: product,
          storage: storage,
          color: color,
        },
        productGroup: productGroup,
        interestedBranch: branch,
        friend_advisor_code: friendAdvisorCode,
        downPayment: "0",
        installments: 0,
        installment: 0,
        totalPrice: 0,
      },
      ["period", "discountPeriod", "discountTurn1"]
    );

    clearData();
    setData(setDt);

    setTimeout(() => {
      return props.onClickBack && props.onClickBack();
    }, 500);
  };

  return (
    <Styles.Container>
      <Styles.MarginRow>
        <Col {...RESPONSIVE_HALF_XS_FULL}>
          <PreviewImage
            disabled={width <= 575 ? true : false}
            image={
              color
                ? _.find(colorModel, (val: any) => val.value === color.code)?.image
                : productModel
                ? _.find(productModel, (val: any) => val.value === product.code)?.image
                : ""
            }
          />
        </Col>
        <Col {...RESPONSIVE_HALF_XS_FULL}>
          <Row>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={productModel || []}
                value={_.find(productModel, (val: any) => val.value === product.code) || null}
                placeholder={"เลือกสินค้า"}
                getOptionLabel={(option: any) => option.label}
                labelId="product"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_MODEL: "" });
                    setProduct({ code: value.value, text: value.label });
                    clearDataProduct();
                  } else if (reason === "clear") {
                    setProduct({ ...ININT_PROD });
                    clearDataProduct();
                  }
                }}
                heading="สินค้า"
                helperText={errorMessage.PRODUCT_MODEL}
                required
                disabled={true}
              />
            </Col>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={storageModel || []}
                value={_.find(storageModel, (val: any) => val.value === storage.code) || null}
                placeholder={"เลือกความจุ"}
                getOptionLabel={(option: any) => option.label}
                labelId="storage"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_STORAGE: "" });
                    setStorage({ code: value.value, text: value.label });
                    clearDataStorage();
                  } else if (reason === "clear") {
                    setStorage({ ...ININT_PROD });
                    clearDataStorage();
                  }
                }}
                heading="ความจุ"
                required
                helperText={errorMessage.PRODUCT_STORAGE}
                disabled={true}
              />
            </Col>
          </Row>

          <Row>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={colorModel || []}
                value={_.find(colorModel, (val: any) => val.value === color.code) || null}
                placeholder={"เลือกสี"}
                getOptionLabel={(option: any) => option.label}
                labelId="color"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_COLOR: "" });
                    setColor({ code: value.value, text: value.label });
                    clearDataColor();
                  } else if (reason === "clear") {
                    setColor({ ...ININT_PROD });
                    clearDataColor();
                  }
                }}
                heading="สี"
                required
                helperText={errorMessage.PRODUCT_COLOR}
                disabled={true}
              />
            </Col>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <InputTextField value={numberFormat(maximum)} heading="ราคาสูงสุด" disabled />
            </Col>
          </Row>

          <Styles.MarginRow>
            <div>
              <span>งวด</span>
              <span style={{ color: colors.danger }}>*</span>
            </div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={_.find(optionsPeriodChoice, (val: any) => val.value === period)?.value || null}
              onChange={(e: any) => {
                if (e) {
                  setErrorMessage({ ...errorMessage, PRODUCT_PERIOD: "", COUPON: "" });
                  setPreiod(parseInt(e.target.value));
                }
              }}
            >
              {optionsPeriodChoice?.map((el: any, idx: number) => {
                return (
                  <FormControlLabel
                    value={el.value}
                    key={idx}
                    control={
                      <Radio
                        key={idx}
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label={el.label}
                  />
                );
              })}
            </RadioGroup>
            {errorMessage.PRODUCT_PERIOD !== "" && (
              <div>
                <HelperText label={errorMessage.PRODUCT_PERIOD} />
              </div>
            )}
          </Styles.MarginRow>

          <Styles.MarginRow>
            <CouponInput
              data={data}
              initStateErrorMessage={initStateErrorMessage}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              discountPeriod={discountPeriod}
              discountTurn1={discountTurn1}
              coupon={coupon}
              setCoupon={setCoupon}
            />
          </Styles.MarginRow>

          <Styles.MarginRow>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="mt-2">
              <InputTextField value={numberFormat(Number(downPayment))} heading={"จำนวนเงินดาวน์"} disabled />
            </Col>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="mt-2">
              <InputTextField value={numberFormat(installments)} heading="จำนวนเงินผ่อน" disabled />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <AutocompleteSelect
              options={branchData || []}
              value={_.find(branchData, (val: any) => val.value === branch) || null}
              placeholder={"เลือกสาขา"}
              groupBy={(option: any) => option.region}
              getOptionLabel={(option: any) => option.label}
              labelId="contracttype"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_BRANCH: "" });
                  setBranch(value.value);
                } else if (reason === "clear") setBranch("");
              }}
              heading="สาขาที่สนใจ"
              helperText={errorMessage.PRODUCT_BRANCH}
              required
            />
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col {...RESPONSIVE_FULL}>
              <InputTextField
                value={friendAdvisorCode}
                onchange={(event: any) => {
                  setFriendAdvisorCode(event.target.value);
                }}
                heading="รหัสผู้แนะนำ (ถ้ามี)"
              />
            </Col>
          </Styles.MarginRow>

          <EstimateText />

          <Styles.MarginRow>
            <Col className="pt-2">
              <ButtonCustom
                variant={"outline"}
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ย้อนกลับ"}
                onClick={() => {
                  onClickBack();
                }}
              />
            </Col>
            <Col className="pt-2">
              <ButtonCustom
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ถัดไป"}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Col>
          </Styles.MarginRow>
        </Col>
      </Styles.MarginRow>
    </Styles.Container>
  );
}
