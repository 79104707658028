import { colors } from "../../../constants/colors";

export default function EstimateText() {
  return (
    <div className="font-14 pt-4" style={{ color: colors.red }}>
      <div>* การประเมินราคาเป็นไปตามเงื่อนไขของทางบริษัท หรือขึ้นอยู่กับสภาพเครื่องของลูกค้า</div>
      <div>* iPhone 11 ขอสงวนสิทธิ์เฉพาะลูกค้าเก่าที่เข้าเงื่อนไขของบริษัทเท่านั้น</div>
    </div>
  );
}
