import { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import EstimateOptionsGroup from "./estimate-options-group";
import * as Inf from "../interface/estimate.interfaces";
import { colors } from "../../../../constants/colors";

interface props {
  optionEstimate: Inf.EstimateDataItem[];
  setDisableSubmit: (value: boolean) => void;
  estimateData: any;
  setEstimateData: (value: any) => void;
  expanded: number[];
  setExpanded: (value: number[]) => void;
  estimateHeader: any;
  setEstimateHeader: (value: any) => void;
  estimateValue: any;
  setEstimateValue: (value: any) => void;
}

const EstimateCollapsible = (props: props) => {
  const {
    optionEstimate,
    setDisableSubmit,
    estimateData,
    setEstimateData,
    expanded,
    setExpanded,
    estimateHeader,
    setEstimateHeader,
    estimateValue,
    setEstimateValue,
  } = props;

  useEffect(() => {
    // set disable submit
    if (_.isEmpty(estimateData) || optionEstimate?.length !== Object.keys(estimateData).length) {
      setDisableSubmit(true);
    } else {
      const isEveryCodeNotEmpty = _.every(estimateData, (item) => !_.isEmpty(item));
      setDisableSubmit(!isEveryCodeNotEmpty);
    }
  }, [estimateData, optionEstimate]);

  const handleSelect = (key: string, value: Inf.EstimateValues, index: number) => {
    const newItem: any = { [key]: value.code };
    const newValues = { ...estimateData, ...newItem };

    setEstimateData(newValues);

    const header = { ...estimateHeader, [key]: value.name };
    setEstimateHeader(header);

    const getNewExpandedState = () => {
      const newExpanded = expanded.filter((i) => i !== index || i === (optionEstimate?.length || 0) - 1);
      return [...newExpanded, index + 1];
    };
    if (value?.expand) {
      setExpanded(getNewExpandedState());
    }
  };

  const handleAccordionChange = (index: number, options: any) => {
    const newExpanded = _.includes(expanded, index) ? _.filter(expanded, (i) => i !== index) : [...expanded, index];
    setExpanded(newExpanded);
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "auto",
      }}
    >
      {_.map(optionEstimate, (options: Inf.EstimateDataItem, index: number) => (
        <Accordion
          key={index}
          expanded={expanded.includes(index)}
          onChange={() => handleAccordionChange(index, options)}
          sx={{
            marginBottom: 2,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              backgroundColor: "#e4efff",
            }}
          >
            <Typography variant="subtitle1">{options.name}</Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Typography variant="subtitle1" color={colors.themeMainColor}>
                {estimateHeader[options?.key] || "เลือกตัวเลือก"}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {_.map(options.value, (sub: Inf.EstimateValue) =>
              sub.sub_name !== options.name ? (
                <>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: "#e4efff",
                    }}
                  >
                    <Typography variant="subtitle1">{sub.sub_name}</Typography>
                  </AccordionSummary>
                  <EstimateOptionsGroup
                    data={sub.sub_key}
                    subKeyLength={options.value.length}
                    multiple={options?.multiple || false}
                    onSelect={(value: Inf.EstimateValues) => handleSelect(options.key, value, index)}
                    selectedCode={estimateData[options?.key] || []}
                    estimateValue={estimateValue}
                    setEstimateValue={setEstimateValue}
                  />
                </>
              ) : (
                <>
                  <EstimateOptionsGroup
                    data={sub.sub_key}
                    multiple={options?.multiple || false}
                    onSelect={(value: Inf.EstimateValues) => handleSelect(options.key, value, index)}
                    selectedCode={estimateData[options?.key] || []}
                    estimateValue={estimateValue}
                    setEstimateValue={setEstimateValue}
                  />
                </>
              )
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default EstimateCollapsible;
