export const RESPONSIVE_FULL = {
  xs: 12,
  sm: 12,
  lg: 12,
  xl: 12,
};

export const RESPONSIVE_HALF = {
  xs: 6,
  sm: 6,
  lg: 6,
  xl: 6,
};


export const RESPONSIVE_HALF_XS_FULL = {
  xs: 12,
  sm: 6,
  lg: 6,
  xl: 6,
};

